import { createContext, useContext, ReactNode } from 'react';

export interface BasePrefixContextProps {
    basePrefix?: string;
}

const BasePrefixContext = createContext<BasePrefixContextProps | undefined>(undefined);

export const useBasePrefix = () => {
    const context = useContext(BasePrefixContext);
    if (!context) {
        throw new Error('useBasePrefix must be used within a BasePrefixProvider');
    }
    return context.basePrefix;
};

interface BasePrefixProviderProps {
    basePrefix?: string;
    children: ReactNode;
}

export const BasePrefixProvider = ({ basePrefix, children }: BasePrefixProviderProps) => {
    return <BasePrefixContext.Provider value={{ basePrefix }}>{children}</BasePrefixContext.Provider>;
};
